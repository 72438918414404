import { LOAD_USER, LOAD_ROLE, LOAD_MODULE, LOAD_CALC } from "../actions/types";
import { LOAD_ITEM } from "../actions/types";

const initialState = {
  user: null,
  role: null,
  module: null,
  item: null,
  calc: null,
};

export default function master(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return { ...state, user: payload.data };
    case LOAD_ROLE:
      return { ...state, role: payload.data };
    case LOAD_MODULE:
      return { ...state, module: payload.data };
    case LOAD_ITEM:
      return { ...state, item: payload.data };
    case LOAD_CALC:
      return { ...state, calc: payload };
    default:
      return state;
  }
}
