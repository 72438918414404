import { combineReducers } from "@reduxjs/toolkit";
import alert from './alert';
import auth from './auth';
import data from './data';
import mixtra from './mixtra';

export default combineReducers({
  alert,
  auth,
  data,
  mixtra,
})
